// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-article-js": () => import("./../src/templates/newsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-service-residential-js": () => import("./../src/templates/serviceResidential.js" /* webpackChunkName: "component---src-templates-service-residential-js" */),
  "component---src-templates-service-commercial-js": () => import("./../src/templates/serviceCommercial.js" /* webpackChunkName: "component---src-templates-service-commercial-js" */),
  "component---src-templates-basic-page-js": () => import("./../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auto-glass-js": () => import("./../src/pages/auto-glass.js" /* webpackChunkName: "component---src-pages-auto-glass-js" */),
  "component---src-pages-commercial-js": () => import("./../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-residential-js": () => import("./../src/pages/residential.js" /* webpackChunkName: "component---src-pages-residential-js" */),
  "component---src-pages-reviews-js": () => import("./../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */)
}

